<template>
	<div>
		<v-toolbar class="activities-tabs" :style="{ 'background-color': primaryColor }">
			<v-tabs v-model="tabs" dark>
				<v-tab>
					<h2>{{ $t('activities.tabTitle') }}</h2>
				</v-tab>
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tabs">
			<v-tab-item> <ActivitiesSchedule :activities="activities" :defaultPhoto="defaultPhoto" /> </v-tab-item>
		</v-tabs-items>
	</div>
</template>


<script>
import ActivitiesSchedule from '@/components/activities/ActivitiesSchedule.vue';

export default {
	name: 'ActivitiesTabs',

	props: {
		activities: {},
		defaultPhoto: {}
	},

	components: { ActivitiesSchedule },

	data() {
		return {
			tabs: null
		};
	},

	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss">
.activities-tabs {
	box-shadow: none !important;
	padding-left: 10%;

	& > .v-toolbar__content > .v-tabs > .v-item-group {
		& > .v-slide-group__prev {
			display: none !important;
		}

		& > .v-slide-group__wrapper > .v-slide-group__content {
			& > .v-tabs-slider-wrapper > .v-tabs-slider {
				border-radius: 8px;
				height: 0.5rem;
			}

			& > .v-tab {
				padding: 0 !important;
				margin-right: 1.5rem !important;
				text-transform: none !important;

				& > h2 {
					font-weight: 500;
					font-size: 14px;
				}

				& > .v-badge {
					// font-family: Cabin;

					& > .v-badge__wrapper > .v-badge__badge {
						color: #575e60;
					}
				}
			}
		}
	}
}
</style>
