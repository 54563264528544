<template>
	<div class="activities">
		<div class="activities__navbar">
			<Navbar />
		</div>
		<div class="activities__header" :style="{ 'background-color': primaryColor }">
			<h1 class="activities__header-title">{{ $t('activities.headerTitle') }}</h1>
			<div class="activities__header-search">
				<!--<SearchBar :label="$t('activities.searchTitle')" />-->
			</div>
		</div>
		<div class="activities__body">
			<ActivitiesTabs :activities="activities" :defaultPhoto="defaultPhoto" />
		</div>
		<footer class="activities__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import ActivitiesTabs from '@/components/activities/ActivitiesTabs.vue';
// import SearchBar from '@/components/common/SearchBar.vue';
import { search, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { getDocument } from '@/api/common.js';
import { insertAudit } from '@/api/user.js';

export default {
	name: 'Activities',
	title() {
		return `${this.$t('activities.headerTitle')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		// SearchBar,
		ActivitiesTabs,
		Footer
	},
	data() {
		return {
			image: '',
			activities: {},
			selectedFilters: [],
			groups: [],
			defaultPhoto: null,
			eventname: '',
			colorStyle: {
				'--primaryColor': this.primaryColor
			}
		};
	},
	mounted() {
		this.getDefaultPhoto();
		this.getEventName();
		const user = JSON.parse(localStorage.getItem('pui9_session'));

		insertAudit({
			usr: user ? user.usr : 'anonymous',
			client: 'CATALOG_' + this.eventname + '_ACTIVITY',
			ip: user ? user.lastLoginIp : '0.0.0.0'
		});
		
		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Listado de actividades",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},
	destroyed() {
		this.unsubscribeToEvents();
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {
		getEventName: function () {
			const aux = window.location.pathname.split('/');
			this.eventname = aux[2];
		},
		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		getActivities: async function () {
			this.$puiEvents.$emit('searching-true_activities');
			this.groups = this.buildGroups();

			const body = {
				filter: {
					groupOp: 'and',
					groups: this.groups,
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'evenid',
							op: 'eq'
						}
					]
				},
				model: 'parallelactivity',
				order: [
					{
						column: 'paacdatetimeini',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: [],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.activities = data.data;
			this.$puiEvents.$emit('searching-false_activities');
		},

		unsubscribeToEvents() {
			this.$puiEvents.$off('searching-true_activities');
			this.$puiEvents.$off('searching-false_activities');
		},

		buildGroups() {
			let variable = [];
			let field = null;

			if (this.$route.query.theme) field = 'pttrname';
			else if (this.$route.query.date) {
				field = 'paacdatetimeini';
				for (let i = 0; i < this.selectedFilters.length; i++) {
					const datePart = this.selectedFilters[i].match(/\d+/g),
						year = datePart[0],
						month = datePart[1],
						day = datePart[2];

					this.selectedFilters[i] = day + '-' + month + '-' + year;
				}
			}

			if (this.selectedFilters.length) {
				let rules = [];
				this.selectedFilters.forEach((element) => {
					rules.push({
						data: element,
						field: field,
						op: 'cn'
					});
				});
				variable = [
					{
						groups: [],
						groupOp: 'or',
						rules: rules
					}
				];
			}
			return variable;
		},

		getFiltersFromQuery: function () {
			this.selectedFilters = [];
			let data = null;

			if (this.$route.query.theme) data = this.$route.query.theme;
			else if (this.$route.query.date) data = this.$route.query.date;

			if (data && Array.isArray(data)) {
				data.forEach((element) => {
					this.selectedFilters.push(element);
				});
			} else if (data) this.selectedFilters.push(data);
		}
	},
	watch: {
		'$route.query': {
			handler: function () {
				this.getFiltersFromQuery();
				this.getActivities();
			},
			deep: true,
			immediate: true
		}
	}
};
</script>

<style lang="scss" scoped>
.activities {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		flex: auto;
		overflow-x: hidden;
	}
}

.activities__header {
	height: 94px;
	display: flex;
	justify-content: space-between;
	padding: 3rem 10%;

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 2rem;
		letter-spacing: 0.0625rem;
		color: #fff;
	}
}
</style>
