<template>
	<v-menu class="date-filter" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="activities-schedule-filters-item border" @click="show = !show" v-bind="attrs" v-on="on" text>
				{{ $t('activities.date') }}
				<v-icon small style="margin-left: 4px">{{ show ? 'fal fa-times' : 'far fa-chevron-down' }}</v-icon>
			</v-btn>
		</template>
		<v-list>
			<v-list-item>
				<v-list-item-title>
					<v-date-picker
						class="date-filter__picker"
						v-model="selectedDates"
						:allowed-dates="allowedDates"
						color="#2498FF"
						no-title
						scrollable
						multiple
						first-day-of-week="1"
						@input="addToQuery()"
					></v-date-picker>
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { search } from '@/api/common.js';

export default {
	name: 'DateFilter',

	components: {},

	data() {
		return {
			selectedDates: [],
			arrayOfAllowedDates: [],
			show: false,
			activities: []
		};
	},

	mounted() {
		this.getActivities();
		this.getDatesFromQuery();
	},

	methods: {
		getActivities: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'evenid',
							op: 'eq'
						}
					]
				},
				model: 'parallelactivity',
				order: [
					{
						column: 'paacdatetimeini',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: [],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.activities = data.data;
			this.getDates();
		},

		getDates: function () {
			this.activities.forEach((date) => {
				if (!this.arrayOfAllowedDates.includes(date.paacdatetimeini.substring(0, 10)))
					this.arrayOfAllowedDates.push(date.paacdatetimeini.substring(0, 10));
			});
		},

		addToQuery() {
			const query = Object.assign({}, this.$route.query);
			delete query.date;
			this.$router.push({ query: { date: this.selectedDates } });
		},

		getDatesFromQuery: function () {
			if (this.$route.query.date) {
				const data = this.$route.query.date;
				console.log(this.$route.query.date);
				if (Array.isArray(data)) this.selectedDates = data;
				else this.selectedDates.push(data);
			}
		},

		allowedDates: function (val) {
			return this.arrayOfAllowedDates.indexOf(val) !== -1;
		}
	},

	watch: {
		'$route.query.date': function () {
			if (!this.$route.query.date) {
				this.selectedDates = [];
			}
		}
	}
};
</script>


<style lang="scss" >
.date-filter__picker > .v-picker__body > div > .v-date-picker-table > table > tbody > tr > td {
	& > .v-date-picker-table__current,
	& > .v-btn--active,
	& > .v-btn:hover {
		border-radius: 50% !important;
	}

	& > .v-btn--outlined {
		color: #000 !important;
		border-color: #000 !important;
	}
}
</style>
