<template>
	<div class="activities-schedule">
		<ActivitiesScheduleFilters :activities="activities" />
		<div v-if="!showProgress">
			<div v-if="activities && activities.length" style="margin: 2.5rem 10%">
				<div
					v-for="(activity, i) in activities"
					:key="activity.paacid"
					class="activities-schedule__container"
					:style="ifNewDate(i) && i != 0 ? 'border-top: 1px solid #000; padding-top: 1.5rem' : ''"
				>
					<div v-if="ifNewDate(i)" class="activities-schedule__container-left" style="width: 30%">
						{{ getDatePretty(activity.paacdatetimeini) }}
					</div>
					<div class="activities-schedule__container-right" style="width: 70%">
						<div class="activities-schedule__card">
							<div class="activities-schedule__card-left">
								<router-link :to="{ name: 'ActivitiesDetails', params: { id: activity.paacid } }">
									<div style="position: relative; left: 0; top: 0">
										<img
											v-if="activity.imageurl"
											class="activities-schedule__card-left-image"
											:src="activity.imageurl"
											alt="photo"
										/>
										<img v-else class="activities-schedule__card-left-image" :src="defaultPhoto" alt="photo" />
										<img
											v-if="activity.paarvideo"
											:src="IconPlay"
											alt="icon"
											style="position: absolute; top: calc(50% - 28px); left: calc(50% - 25.5px)"
										/>
									</div>
								</router-link>
							</div>
							<div class="activities-schedule__card-right">
								<div>
									<router-link :to="{ name: 'ActivitiesDetails', params: { id: activity.paacid } }" style="text-decoration: none">
										<h3 class="activities-schedule__card-right-title">{{ activity.patrname }}</h3>
									</router-link>
									<div
										v-if="activity.patrdescrip"
										class="activities-schedule__card-right-description"
										v-html="activity.patrdescrip"
									></div>
									<div
										v-if="activity.patrspeakers"
										class="activities-schedule__card-right-speakers"
										v-html="activity.patrspeakers"
									></div>
									<span class="activities-schedule__card-right-date">
										{{ getFullDatePretty(activity.paacdatetimeini, activity.paacdatetimeend) }}
									</span>
									<div class="activities-schedule__card-right-badges">
										<div
											class="activities-schedule__card-right-badges-item"
											:style="`background-color: ${HEXtoRGBA(activity.pathcolor, 20)};
										border: 1px solid ${activity.pathcolor};
										color: ${activity.pathcolor};`"
										>
											{{ activity.pttrname }}
										</div>
									</div>
								</div>
								<div class="activities-schedule__card-right-social">
									<button
										style="
											border-radius: 8px;
											padding: 0.75rem;
											max-height: 2.5rem;
											display: flex;
											align-items: center;
											margin: 0 0.25rem;
										"
										@click="
											createCalendar(
												activity.patrname,
												activity.patrdescrip,
												activity.paarlocation,
												activity.paacdatetimeini,
												activity.paacdatetimeend,
												getShareURL(activity.paacid),
												$store.state.eventInfo.evtrname
											)
										"
									>
										<img :src="IconCalendar" alt="appointment" />
									</button>
									<ShareDropdown :url="getShareURL(activity.paacid)" />
									<!--<Modal :exhibitors="[]" />-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="activities-schedule__empty">
				<h3 class="activities-schedule__empty-title">{{ $t('activities.empty') }}</h3>
			</div>
		</div>
		<div v-else style="margin-left: 50%; margin-top: 5%; margin-bottom: 5%">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
	</div>
</template>

<script>
import ActivitiesScheduleFilters from '@/components/activities/ActivitiesScheduleFilters.vue';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
// import Modal from '@/components/common/Modal.vue';

export default {
	name: 'ActivitiesSchedule',

	props: {
		activities: {},
		defaultPhoto: {}
	},

	components: { ActivitiesScheduleFilters, ShareDropdown /*, Modal*/ },

	data() {
		return {
			IconCalendar: require('@/assets/icons/calendar-black.svg'),
			IconPlay: require('@/assets/icons/play.svg'),
			itsNewDate: false,
			showProgress: true
		};
	},
	created() {
		this.subscribeToEvents();
	},

	methods: {
		getShareURL: function (activityId) {
			return window.location.origin + this.$router.resolve({ name: 'ActivitiesDetails', params: { id: activityId } }).href;
		},

		ifNewDate(indice) {
			if (indice !== 0) {
				if (this.getDatePretty(this.activities[indice].paacdatetimeini) !== this.getDatePretty(this.activities[indice - 1].paacdatetimeini)) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		},

		getDatePretty: function (date) {
			const d = new Date(date);
			const options = { weekday: 'long', month: 'long', day: 'numeric' };
			const prettier = d.toLocaleDateString(undefined, options);
			return prettier;
		},

		getFullDatePretty: function (dateini, dateend) {
			const dini = new Date(dateini);
			const dend = new Date(dateend);
			const optionsini = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
			const optionsend = { hour: '2-digit', minute: '2-digit' };
			let prettier = dini.toLocaleDateString(undefined, optionsini) + 'h';
			if (dateend) prettier = prettier + ' - ' + dend.toLocaleTimeString(undefined, optionsend) + 'h';
			return prettier;
		},

		HEXtoRGBA: function (hex, opacity) {
			hex = hex.replace('#', '');
			const r = parseInt(hex.substring(0, 2), 16);
			const g = parseInt(hex.substring(2, 4), 16);
			const b = parseInt(hex.substring(4, 6), 16);
			return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
		},

		subscribeToEvents() {
			this.$puiEvents.$on('searching-true_activities', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false_activities', () => {
				this.showProgress = false;
			});
		},

		createCalendar: function (title, descr, location, dateini, dateend, url, organizer) {
			this.$ics.addEvent(title, title, location, new Date(dateini), new Date(dateend), url, { name: organizer });
			this.$ics.download(title);
			this.$ics.removeAllEvents();
		}
	},

	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss" scoped>
.activities-schedule {
	&__container {
		display: flex;
		justify-content: end;

		&-left {
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}

	&__empty {
		height: 80vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&-title {
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}
}

.activities-schedule__card {
	display: flex;
	padding-bottom: 1.5rem;
	&-left {
		display: flex;
		align-items: center;

		&-image {
			width: 211px;
			height: 174px;
			object-fit: cover;
			position: relative;
			left: 0;
			top: 0;
		}
	}

	&-right {
		padding-left: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;

		&-title {
			font-family: Cabin;
			font-size: 22px;
			color: #293133;
		}

		&-description {
			font-size: 16px;
			line-height: 21px;
			color: #575e60;
			margin-bottom: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			max-height: 42px;
		}

		&-speakers {
			font-size: 14px;
			line-height: 21px;
			color: #868c8d;
			margin-bottom: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			max-height: 21px;
		}

		&-date {
			color: #868c8d;
		}

		&-badges {
			padding-top: 0.5rem;
			display: flex;
			text-align: center;
			&-item {
				border-radius: 6px;
				padding: 0.125rem 0.375rem;
				font-weight: 500;
				font-size: 12px;
				margin-right: 0.5rem;
			}
		}

		&-social {
			display: flex;
			justify-content: end;
		}
	}
}
</style>
