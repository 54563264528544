<template>
	<v-menu class="category-filter" :close-on-content-click="false" offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="activities-schedule-filters-item" @click="show = !show" v-bind="attrs" v-on="on" text>
				{{ $t('activities.thematic') }}
				<div v-if="selectedThemes.length" style="width: 100%; text-align: start; padding-left: 0.25rem">
					<span class="category-filter__num-selecteds">{{ selectedThemes.length }}</span>
				</div>
				<v-icon small style="margin-left: 4px">{{ show ? 'fal fa-times' : 'far fa-chevron-down' }}</v-icon>
			</v-btn>
		</template>
		<v-list>
			<v-list-item v-for="theme in themes" :key="theme.pathid">
				<v-list-item-title
					><v-checkbox :label="theme.pttrname" @change="isSelected(theme.pttrname)" v-model="theme.checked"></v-checkbox
				></v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { search } from '@/api/common.js';
export default {
	name: 'CategoryFilter',

	data() {
		return {
			show: false,
			themes: null,
			selectedThemes: []
		};
	},

	mounted() {
		this.getInfo();
		this.getThemesFromQuery();
	},

	methods: {
		getInfo: async function () {
			const body = {
				model: 'parallelactivitytheme',
				page: 1,
				rows: 20,
				queryLang: 'es',
				queryText: null,
				queryFields: [],
				queryFlexible: false,
				filter: {
					groupOp: 'and',
					rules: [
						{
							field: 'patheventid',
							op: 'eq',
							data: this.$store.state.eventInfo.evenid
						}
					],
					groups: []
				},
				order: [
					{
						column: 'pttrname',
						direction: 'asc'
					}
				]
			};

			const data = await search(body);
			this.themes = data.data;
			this.themes.forEach((theme) => {
				this.selectedThemes.forEach((selectedTheme) => {
					if (theme.pttrname === selectedTheme) {
						theme.checked = true;
					}
				});
			});
		},

		addTheme: function (theme) {
			this.selectedThemes.push(theme);
			const query = Object.assign({}, this.$route.query);
			delete query.theme;
			this.$router.replace({ query: { theme: this.selectedThemes } });
		},

		removeTheme: function (theme) {
			const index = this.selectedThemes.indexOf(theme);
			if (index > -1) {
				this.selectedThemes.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.theme;
			this.$router.replace({ query });
			this.$router.push({ query: { theme: this.selectedThemes } });
		},

		isSelected(theme) {
			let isSelected = false;
			this.selectedThemes.forEach((element) => {
				if (element === theme) {
					isSelected = true;
				}
			});

			isSelected ? this.removeTheme(theme) : this.addTheme(theme);
		},

		getThemesFromQuery: function () {
			if (this.$route.query.theme) {
				const data = this.$route.query.theme;
				if (Array.isArray(data)) this.selectedThemes = data;
				else this.selectedThemes.push(data);
			}
		}
	},

	watch: {
		'$route.query': function () {
			if (!this.$route.query.theme) {
				this.selectedThemes = [];
				this.getInfo();
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.category-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}
}
</style>
