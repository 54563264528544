<template>
	<div class="activities-schedule-filters">
		<CategoryFilter />
		<DateFilter :activities="activities" />
		<!--<ModelFilter />
		<EventTypeFilter />
		<OthersFilter />-->
	</div>
</template>

<script>
import CategoryFilter from '@/components/activities/filters/CategoryFilter.vue';
import DateFilter from '@/components/activities/filters/DateFilter.vue';
// import ModelFilter from '@/components/activities/filters/ModelFilter.vue';
// import EventTypeFilter from '@/components/activities/filters/EventTypeFilter.vue';
// import OthersFilter from '@/components/activities/filters/OthersFilter.vue';

export default {
	name: 'ActivitiesScheduleFilters',

	props: {
		activities: {}
	},

	data() {
		return {
			show: false
		};
	},

	components: { CategoryFilter, DateFilter /*, ModelFilter, EventTypeFilter, OthersFilter*/ }
};
</script>


<style lang="scss">
.activities-schedule-filters {
	padding: 0 8%;
	border-bottom: 1px solid #e5e5e5;
	display: flex;

	&-item {
		width: 20%;
		display: flex;
		justify-content: space-between;
		height: 3rem !important;
		border-radius: 0 !important;

		& > .v-btn__content {
			font-family: Cabin;
			font-weight: bold;
			color: #575e60;
		}
	}

	&-item.border {
		border-right: 1px solid #e5e5e5;
		border-left: 1px solid #e5e5e5;
	}
}

.v-menu__content {
	border-radius: 8px;
	margin-top: 0.5rem;
	box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.09), 0px 1px 5px rgba(0, 0, 0, 0.09);
}
</style>
